import React, { useContext } from 'react';
import { Text, View, Image } from 'react-native';
import { createMaterialTopTabNavigator, createStackNavigator, createAppContainer } from "react-navigation";
import {styles, Colors} from "../foundation/styles";
import {UnassignedUser} from '../section_home/views';
import Login, {LogoutButton} from '../section_home/login';
import {
  CourseChooserButton,
  SchoolChooserButton,
  CloudChooserButton
} from '../section_home/views';
import {
  Dashboard,
} from '../section_home/dashboard';
import {
  WizardCloudChooserView, WizardLocationChooserView, WizardCourseChooserView
} from '../section_home/wizard/views';
import ConfigurationNav from '../section_configuration/index';
import ClassesNav from '../section_classes/index';
import PropectsNav from '../section_prospects/index';
import StatisticsNav from '../section_statistics/index';
import UserContext from '../user'
import { MaterialTopTabBar } from 'react-navigation-tabs/dist/index';
import {Observer} from 'mobx-react';
import {PortalButton, AutoscuolaLogo} from "../foundation/widgets";
import {ImageBackground} from "react-native-web";


const TabBarComponent = ({ navigation, ...rest }) => {

  const store = useContext(UserContext);

  return (
    <Observer>
      {() => {
        let excludedRoutes = [];
        if (!store.isAllowedUser) {
          excludedRoutes = ['Prospects', 'Configuration']
        }
        if (store.hasUnprocessedEdits) {
          return (
            <View style={{position: 'relative'}}>
              <View style={styles.tabBarOverlay}/>
              <MaterialTopTabBar
                {...rest}
                navigation={{
                  ...navigation,
                  state: {
                    ...navigation.state,
                    routes: navigation.state.routes.filter(r => !excludedRoutes.includes(r.routeName))
                  }
                }}
              />
            </View>
          )
        } else {
          return (
            <MaterialTopTabBar
              {...rest}
              navigation={{
                ...navigation,
                state: {
                  ...navigation.state,
                  routes: navigation.state.routes.filter(r => !excludedRoutes.includes(r.routeName))
                }
              }}
            />
          );
        }
      }
      }
    </Observer>
  )

}


const TabNavigator = createMaterialTopTabNavigator({
  Dashboard: {
    screen: Dashboard,
    path: 'dashboard/'
  },

  Classes: {
    screen: ClassesNav,
    path: 'classes/'
  },
  Prospects: {
      screen: PropectsNav,
      path: 'students/'
    },
  // Messages: {
  //     screen: MessaegesNav,
  //     path: 'messages/'
  //   },
  Statistics: {
      screen: StatisticsNav,
      path: 'statistics/'
  },
  Configuration: {
    screen: ConfigurationNav,
    path: 'configuration/'
  },
},
{
  initialRouteName: 'Dashboard',
  animationEnabled: false,
  swipeEnabled: false,

  tabBarOptions: {
    showIcon: true,
    scrollEnabled: false,
    // activeTintColor: '#ffffff',
    // inactiveTintColor: '#ffffff',
    // activeBackgroundColor: Colors.tabSelected, not working with material tabnav


    labelStyle: {
      fontSize: 16,
    },
    indicatorStyle: {
      backgroundColor: '#5c5c5c', // New egaf color. SELECTED tab bg color
      height: '100%',
      // opacity: 0
    },
    style: {
      // backgroundColor: '#ffffff', // New egaf color. #ffffff -> #7a7a7a . '#9e9e9e' OLD
      backgroundColor: '#7d7d7d', // tab bg color
    },
    tabStyle: {
      borderRightWidth: 2,
      // borderRightColor: Colors.border,
      borderRightColor: '#2a2a2a',
      // backgroundColor: '#404040', // New egaf color. Colors.border, OLD
      // backgroundColor: '#00ff00', // New egaf color. Colors.border, OLD
      flexDirection: 'row',
      justifyContent: 'center',

    },
  },
  navigationOptions:(props) => ({
    header: props => <CustomHeaderComplete {...props} />,
    // headerLeft: null,
    // headerRight: (
    //   <View style={[styles.topActionBtns]}>
    //     <View style={[styles.rowContainer, styles.alignMiddle]}>
    //       <PortalButton />
    //       <CloudChooserButton {...props} />
    //       <SchoolChooserButton {...props} />
    //       <CourseChooserButton {...props} />
    //     </View>
    //   <LogoutButton {...props} />
    // </View>
    // )
  }),
  defaultNavigationOptions: ({ navigation }) => ({
    tabBarIcon: ({ focused, horizontal, tintColor }) => {
      const { routeName } = navigation.state;
      let IconComponent;
      if (routeName === 'Dashboard') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/home.svg')}/>;
      } else if (routeName === 'Configuration') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/config.svg')}/>;
      } else if (routeName === 'Classes') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/classes.svg')}/>;
      } else if (routeName === 'Prospects') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/contacts.svg')}/>;
      } else if (routeName === 'Messages') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/messages.svg')}/>;
      } else if (routeName === 'Statistics') {
        IconComponent = <Image size={25} style={{width: 50, height: 50}} source={require('../commons/images/stats.svg')}/>;
      }

      // You can return any component that you like here!
      return IconComponent;
    },

    tabBarLabel: () => {
      const { routeName } = navigation.state;
      let label;
      if (routeName === 'Dashboard') {
        label = 'DASHBOARD';
      }  else if (routeName === 'Configuration') {
        label = 'CONFIGURAZIONI';
      } else if (routeName === 'Classes') {
        label = 'CLASSI';
      } else if (routeName === 'Prospects') {
        label = 'CONTATTI';
      } else if (routeName === 'Messages') {
        label = 'MESSAGGI';
      } else if (routeName === 'Statistics') {
        label = 'STATISTICHE';
      }
      return <Text style={styles.tabTextStyle}>{label}</Text>;
    },
    tabBarComponent: TabBarComponent,
  }),
});


class LogoTitle extends React.Component {
  render() {
    return (
      <Image style={{ width: 218, height: 45, marginLeft: 30 }} source={require('../commons/images/logo_egaf.png')}/>
    );
  }
}


const CustomHeader = (props) => {

    return (
      <ImageBackground style={styles.topHeaderBg} resizeMode="cover" source={require('../commons/images/header_bg.png')}>
        <View style={styles.topHeaderWrapper}>
          <LogoTitle />
        </View>
      </ImageBackground>
    );
};


const CustomHeaderComplete = (props) => {

  return (
    <ImageBackground style={styles.topHeaderBg} resizeMode="cover" source={require('../commons/images/header_bg.png')}>
      <View style={styles.topHeaderCompleteWrapper}>
        <LogoTitle />
        <div style={{flexGrow: "1"}}>

        </div>
        <View style={[styles.topActionBtns]}>
          <View style={[styles.rowContainer, styles.alignMiddle]}>
            <CloudChooserButton {...props} />
            <SchoolChooserButton {...props} />
            <CourseChooserButton {...props} />
          </View>
          <LogoutButton {...props} />
          <PortalButton />
        </View>
        <span className="logo-autoscuola" style={{
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            background: "linear-gradient(to right, gainsboro 1%, white 5%)",
        }}>
            <AutoscuolaLogo />
        </span>
      </View>
    </ImageBackground>
  );
};


const MainNavigator = createStackNavigator(
  {
    Login: {
      screen: Login,
    },

    // Wizard process
    WizardCloudChooser: {
      screen: WizardCloudChooserView,
      navigationOptions: {
        headerLeft: null,
      }
    },
    WizardLocationChooser: {
      screen: WizardLocationChooserView,
      navigationOptions: {
        headerLeft: null,
      }
    },
    WizardCourseChooser: {
      screen: WizardCourseChooserView,
      navigationOptions: {
        headerLeft: null
      }
    },
    // Wizard process
    Unassigned: {
      screen: UnassignedUser,
    },

    App: {
      screen: TabNavigator,
    },

  },
  {
    initialRouteName: 'Login',
    mode: 'modal',
    headerMode: 'float',
    cardShadowEnabled: false,
    defaultNavigationOptions: {

      header: props => <CustomHeader {...props}/>,
      // headerTitle: EgafAppName,
      headerTitle: <LogoTitle />,
      headerTintColor: '#fff',
      headerStyle: {
        backgroundColor: Colors.navBg,
        shadowOffset: {height: 0, width: 0},
        shadowRadius: 0,
        shadowColor: '#ff0000',
      },
    }
  }
);




export default createAppContainer(MainNavigator);
